/* headers */
h1 {
	@apply text-36 lg:text-5xl xl:text-60;
}

h2 {
	@apply text-20 lg:text-4xl xl:text-40;
}

h3 {
	@apply text-16 lg:text-30;
}

h4 {
	@apply text-xl lg:text-25;
}

h5 {
	@apply text-xs lg:text-22;
}

h6 {
	@apply text-18;
}

.summary h1,
.page-content h1,
.summary h2,
.page-content h2,
.summary h3,
.page-content h3,
.summary h4,
.page-content h4,
.summary h5,
.page-content h5,
.summary h6:not([role="tooltip"] h6),
.page-content h6 {
	@apply mt-3 font-lora font-medium text-primary;
}
.summary h1,
.other-faqs h1,
.page-content h1 {
	@apply text-[28px] leading-normal xl:text-3xl 2xl:text-4xl;
}
.summary h2,
.other-faqs h2,
.page-content h2 {
	@apply text-2xl xl:text-25 2xl:text-30;
}
.summary h3,
.other-faqs h3,
.page-content h3 {
	@apply text-xl xl:text-22 2xl:text-[28px] 2xl:leading-9;
}
.summary h4,
.other-faqs h4,
.page-content h4 {
	@apply text-lg xl:text-2xl xl:text-[26px];
}
.summary h5,
.other-faqs h5,
.page-content h5 {
	@apply text-2xl;
}
.summary h6:not([role="tooltip"] h6),
.other-faqs h6,
.page-content h6 {
	@apply text-[22px];
}
/* end headers */

.summary,
.other-faqs,
.page-content {
	@apply overflow-x-auto text-gray-900 md:overflow-x-visible;
}

.faq-question span {
	@apply text-primary;
}

.summary,
.page-content {
	ol,
	ul {
		@apply my-5 ml-10 space-y-3;
	}

	ol {
		@apply list-decimal;
	}

	ul {
		@apply list-circle;
	}

	li {
		@apply marker:text-primary;
	}
}

.overview a {
	@apply text-white underline hover:text-examine-purple-400;
}
.overview a sup {
	@apply text-white underline hover:text-examine-purple-400;
}
.summary a,
.search-content a,
.page-content a {
	@apply text-examine-purple-400 hover:underline;
}

.summary p {
	@apply py-2;
}

.summary p small,
.page-content p small {
	line-height: -20px !important;
}
.other-faqs a {
	@apply text-examine-purple-400 hover:underline;
}

.summary a sup,
.overview a sup,
.other-faqs a sup,
.page-content a sup {
	@apply font-normal;
}

.child-faq h1 {
	@apply text-[15px] leading-normal xl:text-22;
}
.child-faq h2 {
	@apply text-[14px] leading-normal xl:text-xl;
}
.child-faq h3 {
	@apply text-[13px] leading-normal xl:text-lg;
}
.child-faq h4 {
	@apply text-[12px] leading-normal xl:text-base;
}
.child-faq h5 {
	@apply text-[12px] leading-normal xl:text-base;
}
.child-faq h6 {
	@apply text-[12px] leading-normal xl:text-base;
}

.page-content {
	p,
	li {
		@apply -tracking-2 xl:text-xl;
	}
	p + p {
		@apply mt-3;
	}

	p {
		@apply leading-7 xl:leading-9;
	}
}

.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5,
.page-content h6 {
	@apply py-3;
}
.page-content blockquote {
	@apply border-l-4 border-info py-2 pl-4 text-primary xl:pl-6 xl:text-xl;
}

.references a {
	@apply break-all;
}

.link {
	@apply cursor-pointer text-examine-purple-400 hover:underline;
}

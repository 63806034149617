.tooltip-overview {
	@apply w-96 max-w-xs p-5;
}

.tooltip-overview h6 {
	@apply -m-5 mb-1 border border-info bg-examine-purple-100 p-3 pl-5 !font-inter !text-16 font-medium text-gray-900;
}

.tooltip-overview p {
	@apply overflow-auto bg-white leading-relaxed text-[#26323d];
}

.tooltip-default > div,
.tooltip-default p {
	@apply w-64 rounded-md bg-white px-3 py-4 pb-5 text-left font-inter text-menu leading-5 text-[#26323d];
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}.__variable_d65c78 {--font-inter: 'Inter', 'Inter Fallback'
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/8976b37f8037a4c0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/ada9171bd6839183-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* math */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/21633f890e359fee-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/9c526da3fdc4ac93-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/d364c42434837330-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/293d9d85a89f760b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/3a8dc5763a8ae4b2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Lora Fallback';src: local("Times New Roman");ascent-override: 87.33%;descent-override: 23.78%;line-gap-override: 0.00%;size-adjust: 115.20%
}.__className_d48d41 {font-family: 'Lora', 'Lora Fallback';font-style: normal
}.__variable_d48d41 {--font-lora: 'Lora', 'Lora Fallback'
}

/* headers */
h1 {
	font-size: 36px;
	line-height: 48px;
}
@media (min-width: 1024px) {
	h1 {
		font-size: 3rem;
		line-height: 1;
	}
}
@media (min-width: 1280px) {
	h1 {
		font-size: 60px;
		line-height: 75px;
	}
}

h2 {
	font-size: 20px;
	line-height: 25px;
}

@media (min-width: 1024px) {
	h2 {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}
}

@media (min-width: 1280px) {
	h2 {
		font-size: 40px;
		line-height: 55px;
	}
}

h3 {
	font-size: 16px;
	line-height: 20px;
}

@media (min-width: 1024px) {
	h3 {
		font-size: 30px;
		line-height: 36px;
	}
}

h4 {
	font-size: 1.25rem;
	line-height: 1.75rem;
}

@media (min-width: 1024px) {
	h4 {
		font-size: 25px;
	}
}

h5 {
	font-size: 0.75rem;
	line-height: 1rem;
}

@media (min-width: 1024px) {
	h5 {
		font-size: 22px;
	}
}

h6 {
	font-size: 18px;
	line-height: 1.5;
}

.summary h1,
.page-content h1,
.summary h2,
.page-content h2,
.summary h3,
.page-content h3,
.summary h4,
.page-content h4,
.summary h5,
.page-content h5,
.summary h6:not([role="tooltip"] h6),
.page-content h6 {
	margin-top: 0.75rem;
	font-family: var(--font-lora), ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
	font-weight: 500;
	--tw-text-opacity: 1;
	color: rgb(94 59 118 / var(--tw-text-opacity, 1));
}
.summary h1,
.other-faqs h1,
.page-content h1 {
	font-size: 28px;
	line-height: 1.5;
}
@media (min-width: 1280px) {
	.summary h1,
.other-faqs h1,
.page-content h1 {
		font-size: 1.875rem;
		line-height: 2.25rem;
	}
}
@media (min-width: 1440px) {
	.summary h1,
.other-faqs h1,
.page-content h1 {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}
}
.summary h2,
.other-faqs h2,
.page-content h2 {
	font-size: 1.5rem;
	line-height: 2rem;
}
@media (min-width: 1280px) {
	.summary h2,
.other-faqs h2,
.page-content h2 {
		font-size: 25px;
	}
}
@media (min-width: 1440px) {
	.summary h2,
.other-faqs h2,
.page-content h2 {
		font-size: 30px;
		line-height: 36px;
	}
}
.summary h3,
.other-faqs h3,
.page-content h3 {
	font-size: 1.25rem;
	line-height: 1.75rem;
}
@media (min-width: 1280px) {
	.summary h3,
.other-faqs h3,
.page-content h3 {
		font-size: 22px;
	}
}
@media (min-width: 1440px) {
	.summary h3,
.other-faqs h3,
.page-content h3 {
		font-size: 28px;
		line-height: 2.25rem;
	}
}
.summary h4,
.other-faqs h4,
.page-content h4 {
	font-size: 1.125rem;
	line-height: 1.75rem;
}
@media (min-width: 1280px) {
	.summary h4,
.other-faqs h4,
.page-content h4 {
		font-size: 1.5rem;
		line-height: 2rem;
		font-size: 26px;
	}
}
.summary h5,
.other-faqs h5,
.page-content h5 {
	font-size: 1.5rem;
	line-height: 2rem;
}
.summary h6:not([role="tooltip"] h6),
.other-faqs h6,
.page-content h6 {
	font-size: 22px;
}
/* end headers */

.summary,
.other-faqs,
.page-content {
	overflow-x: auto;
	--tw-text-opacity: 1;
	color: rgb(33 37 41 / var(--tw-text-opacity, 1));
}

@media (min-width: 768px) {
	.summary,
.other-faqs,
.page-content {
		overflow-x: visible;
	}
}

.faq-question span {
	--tw-text-opacity: 1;
	color: rgb(94 59 118 / var(--tw-text-opacity, 1));
}

.summary ol,
	.page-content ol,
	.summary ul,
	.page-content ul {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	margin-left: 2.5rem;
}

.summary ol > :not([hidden]) ~ :not([hidden]),
	.page-content ol > :not([hidden]) ~ :not([hidden]),
	.summary ul > :not([hidden]) ~ :not([hidden]),
	.page-content ul > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.summary ol, .page-content ol {
	list-style-type: decimal;
}

.summary ul, .page-content ul {
	list-style-type: circle;
}

.summary li *::marker, .page-content li *::marker {
	color: rgb(94 59 118 );
}

.summary li::marker, .page-content li::marker {
	color: rgb(94 59 118 );
}

.overview a {
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity, 1));
	text-decoration-line: underline;
}

.overview a:hover {
	--tw-text-opacity: 1;
	color: rgb(140 104 205 / var(--tw-text-opacity, 1));
}
.overview a sup {
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity, 1));
	text-decoration-line: underline;
}
.overview a sup:hover {
	--tw-text-opacity: 1;
	color: rgb(140 104 205 / var(--tw-text-opacity, 1));
}
.summary a,
.search-content a,
.page-content a {
	--tw-text-opacity: 1;
	color: rgb(140 104 205 / var(--tw-text-opacity, 1));
}
.summary a:hover,
.search-content a:hover,
.page-content a:hover {
	text-decoration-line: underline;
}

.summary p {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.summary p small,
.page-content p small {
	line-height: -20px !important;
}
.other-faqs a {
	--tw-text-opacity: 1;
	color: rgb(140 104 205 / var(--tw-text-opacity, 1));
}
.other-faqs a:hover {
	text-decoration-line: underline;
}

.summary a sup,
.overview a sup,
.other-faqs a sup,
.page-content a sup {
	font-weight: 400;
}

.child-faq h1 {
	font-size: 15px;
	line-height: 1.5;
}

@media (min-width: 1280px) {
	.child-faq h1 {
		font-size: 22px;
	}
}
.child-faq h2 {
	font-size: 14px;
	line-height: 1.5;
}
@media (min-width: 1280px) {
	.child-faq h2 {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}
.child-faq h3 {
	font-size: 13px;
	line-height: 1.5;
}
@media (min-width: 1280px) {
	.child-faq h3 {
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
}
.child-faq h4 {
	font-size: 12px;
	line-height: 1.5;
}
@media (min-width: 1280px) {
	.child-faq h4 {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}
.child-faq h5 {
	font-size: 12px;
	line-height: 1.5;
}
@media (min-width: 1280px) {
	.child-faq h5 {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}
.child-faq h6 {
	font-size: 12px;
	line-height: 1.5;
}
@media (min-width: 1280px) {
	.child-faq h6 {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

.page-content p,
	.page-content li {
	letter-spacing: -0.02em;
}

@media (min-width: 1280px) {
	.page-content p,
	.page-content li {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}

.page-content p + p {
	margin-top: 0.75rem;
}

.page-content p {
	line-height: 1.75rem;
}

@media (min-width: 1280px) {
	.page-content p {
		line-height: 2.25rem;
	}
}

.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5,
.page-content h6 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}
.page-content blockquote {
	border-left-width: 4px;
	--tw-border-opacity: 1;
	border-color: rgb(226 217 243 / var(--tw-border-opacity, 1));
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	--tw-text-opacity: 1;
	color: rgb(94 59 118 / var(--tw-text-opacity, 1));
}
@media (min-width: 1280px) {
	.page-content blockquote {
		padding-left: 1.5rem;
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}

.references a {
	word-break: break-all;
}

.link {
	cursor: pointer;
	--tw-text-opacity: 1;
	color: rgb(140 104 205 / var(--tw-text-opacity, 1));
}

.link:hover {
	text-decoration-line: underline;
}

input,
select,
textarea {

    border-radius: 0.375rem;

    --tw-border-opacity: 1;

    border-color: rgb(206 212 218 / var(--tw-border-opacity, 1))
}

input:hover,
select:hover,
textarea:hover {

    --tw-shadow: 0px 4px 16px rgba(184, 218, 255, 0.12);

    --tw-shadow-colored: 0px 4px 16px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

input:focus,
select:focus,
textarea:focus {

    --tw-border-opacity: 1;

    border-color: rgb(147 51 234 / var(--tw-border-opacity, 1));

    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    outline: 2px solid transparent;

    outline-offset: 2px;

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

button:focus {

    outline: 2px solid transparent;

    outline-offset: 2px
}

.tooltip-overview {

    width: 24rem;

    max-width: 20rem;

    padding: 1.25rem
}

.tooltip-overview h6 {

    margin: -1.25rem;

    margin-bottom: 0.25rem;

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(226 217 243 / var(--tw-border-opacity, 1));

    --tw-bg-opacity: 1;

    background-color: rgb(239 235 242 / var(--tw-bg-opacity, 1));

    padding: 0.75rem;

    padding-left: 1.25rem;

    font-family: var(--font-inter), ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;

    font-size: 16px !important;

    line-height: 20px !important;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(33 37 41 / var(--tw-text-opacity, 1))
}

.tooltip-overview p {

    overflow: auto;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));

    line-height: 1.625;

    --tw-text-opacity: 1;

    color: rgb(38 50 61 / var(--tw-text-opacity, 1))
}

.tooltip-default > div,
.tooltip-default p {

    width: 16rem;

    border-radius: 0.375rem;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    padding-top: 1rem;

    padding-bottom: 1.25rem;

    text-align: left;

    font-family: var(--font-inter), ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    font-size: 15px;

    line-height: 20px;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(38 50 61 / var(--tw-text-opacity, 1))
}

:root {
    --chart-purple: 275.59 33.33% 34.71%;
    --chart-green: 186.09 65.09% 41.57%;
    --chart-teal: 162.25 72.53% 45.69%;
}

.dark {
    --chart-purple: 275.59 33.33% 34.71%;
    --chart-green: 186.09 65.09% 41.57%;
    --chart-teal: 162.25 72.53% 45.69%;
}
/* table {
  @apply w-full rounded-lg border-none text-black;
}
thead {
  @apply rounded-t-lg;
}

thead th {
  @apply whitespace-nowrap border-1 border-examine-purple-400 bg-primary px-3 py-3 text-13 font-medium
  text-white shadow-table-head sm:whitespace-normal  sm:px-0 sm:py-5 sm:text-base sm:font-normal;
}

td {
  @apply border-1 border-gray-300 p-3 text-13 sm:p-5 sm:text-[15px] lg:text-lg lg:leading-9;
}

tbody tr:nth-child(even) {
  @apply bg-white;
}
tbody tr:nth-child(odd) {
  @apply bg-extra-gray-500;
}

.table-responsive {
  @apply w-[87vw] overflow-x-auto sm:w-[85vw] md:w-[92vw] lg:w-full;
} */

/* source: https://boyl.es/post/css-reset-for-tables/ */
table.grid-table thead,
table.grid-table tbody,
table.grid-table tr {
	display: grid;
	grid-column: 1 / -1;
	grid-template-columns: subgrid;
}

table.grid-table td,
table.grid-table th {
	display: flex;
	align-items: center;
	justify-content: center;
}

table.grid-table caption {
	grid-column: 1 / -1;
}

.sales-interest-cards div {
	flex: 0 0 calc(50% - 10px);
}

@media (min-width: 768px) {
	.sales-interest-cards div {
		flex: 0 0 calc(32% - 10px);
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

.keen-slider:not([data-keen-slider-disabled]){-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent;align-content:flex-start;display:flex;overflow:hidden;position:relative;touch-action:pan-y;-webkit-user-select:none;-moz-user-select:none;user-select:none;-khtml-user-select:none;width:100%}.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide{min-height:100%;overflow:hidden;position:relative;width:100%}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse]{flex-direction:row-reverse}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v]{flex-wrap:wrap}
